import { aboutTextEn, aboutTextTh } from './about-us'
import {
  changeTermTextEn,
  changeTermTextTh,
  lawTextEn,
  lawTextTh,
  subHeaderEn,
  subHeaderTh,
  webAccessTextEn,
  webAccessTextTh,
} from './term-and-condition'

export const languages = [
  {
    label: 'ภาษาไทย',
    value: 'th',
  },
  {
    label: 'English',
    value: 'en',
  },
]

export const getTranslation = (key: 'en' | 'th') => {
  const text = (en: string, th: string): string => ({ en, th }[key])
  return {
    [key]: {
      translation: {
        app: {
          seeAll: text('View all', 'ดูทั้งหมด'),
          inputAmount: text('Input Amount', 'ใส่จำนวนเงิน'),
          orderNumber: text('Order Number', 'รายการหมายเลข'),
          amount: text('Amount', 'จำนวนเงิน'),
          selectHelp: text('Please select a request for assistance.', 'กรุณาเลือกรายการขอเบิกความช่วยเหลือ'),
          typeOf: text('Type of ', 'ประเภท'),
          selectDate: text('Select Date', 'เลือกวันที่'),
          titleEclaim: text('Details of the claim requested', 'รายละเอียดสวัสดิการที่ยื่นคำขอ'),
          attachment: text('Attached documents', 'เอกสารแนบ'),
          attachmentSize: text('jpg, png, pdf no more than 3 MB', 'jpg, png, pdf ขนาดไม่เกิน 3 MB'),
          claimName: text('claim name', 'ชื่อการเบิก'),
          description: text('Description', 'รายละเอียด'),
          adminPage: text('Enter to Admin Page', 'เข้าสู่หน้าแอดมิน'),
          point: text('Point', 'คะแนน'),
          coin: text('Coin', 'เหรียญ'),
          sync: text('', 'ซิงค์ล่าสุด'),
          remain: text('REMAIN', 'คงเหลือ'),
          coinRemain: text('Coin remain', 'เหรียญคงเหลือ'),
          payAdditional: text('Pay Additional', 'มีค่าใช้จ่ายเพิ่มเติม'),
          continue: text('Continue', 'ดำเนินการต่อ'),
          photoUnit: text('photo', 'ภาพ'),
          ConvertedToMoney: text('Converted to money', 'คิดเป็นเงิน'),
          upload: {
            photo: text('Upload photo', 'อัพโหลดรูปภาพ'),
            gallery: text('Upload from Gallery', 'อัปโหลดจากคลังภาพ'),
            success: text('คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว', 'คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว'),
          },
          navbar: {
            profile: text('Profile', 'Profile'),
            app: text('App', 'App'),
            termConditions: text('Terms and Conditions', 'Terms and Conditions'),
            about: text('About Belive', 'เกี่ยวกับ Belive'),
            language: text('Language', 'ภาษา'),
            version: text('Version', 'เวอร์ชัน'),
            logout: text('Log Out', 'ออกจากระบบ'),
          },
          breadcrumb: {
            insurance: text('Benefit adjustment', 'ปรับเปลี่ยนสวัสดิการ'),
            seeInsurance: text('ดูสวัสดิการ', 'ดูสวัสดิการ'),
            myInsurance: text('Your insurance plan', 'ประกันของคุณ'),
            myWelFare: text('Your flexible benefits', 'สวัสดิการของคุณ'),
            changeInsurance: text('Change insurance plan', 'ปรับเปลี่ยนแผนประกัน'),
            myHealthCheckup: text('แผนตรวจสุขภาพของคุณ', 'แผนตรวจสุขภาพของคุณ'),
            changeHealthCheckup: text('เลือกโรงพยาบาล​', 'เลือกโรงพยาบาล​')
          },
          coinNow: text('ปัจจุบันคุณมีเหรียญ', 'ปัจจุบันคุณมีเหรียญ'),
        },
        disaster: {
          content: text('Actual expenses with maximum 20,000 Baht per month and maximum 1 month per incident', 'เบิกตามจริงไม่เกิน 20,000 ฿ต่อเดือนและไม่เกิน 1 เดือนต่อเหตุการณ์'),
          content2: text('Actual expenses with maximum 300 Baht per day and maximum 10 days per incident', 'เบิกตามจริงไม่เกิน 300 ฿ต่อวัน และไม่เกิน 10 วันต่อเหตุการณ์'),
          content3: text('Damage aid to houses and/or home appliances according to policy', 'เงินช่วยเหลือค่าความเสียหายของบ้าน และ/หรือ สิ่งของเครื่องใช้ภายในบ้าน ตามนโยบาย')
        },
        main: {
          title: text('List of Benefits Items', 'ต้องการเบิกสวัสดิการ'),
          history: text('Claims History', 'ประวัติการเบิก'),
          attached: text('Attached documents', 'แนบเอกสาร'),
          acceptButton: text('Accept', 'ยินยอม'),
          clickHere: text('Click this button', 'คลิ๊กปุ่มนี่'),
          eReceiptCheck: text(` if the attached receipt is an electronic receipt.`, ` ถ้าใบเสร็จรับเงินที่แนบ เป็นใบเสร็จรับเงินอิเล็กทรอนิกส์`),
          eReceiptCheck_2: text(`( The electronic receipt will be specified that "this document has been created and electronically submitted to the Revenue Department")`, `(ข้อสังเกตุของใบเสร็จรับเงินอิเล็กทรอนิกส์ระบุ "เอกสารนี้ได้จัดทำและนำส่งข้อมูลให้กรมสรรพากรด้วยวิธีการทางอิเล็กทรอนิกส์")`)
        },
        profileCard: {
          availableCoin: text('Available coin', 'เหรียญที่มีอยู่'),
          titleJob: text('Position', 'ตำแหน่งงาน'),
          noJob: text('No job', 'ไม่มีอาชีพ'),
          coinHistory: text('Coins usage history', 'ประวัติการใช้เหรียญ'),
        },
        home: {
          subtitle: text('FSA Reimbursement', 'การเบิก FSA'),
          validUntil: text('Valid until', 'ใช้ได้ถึงวันที่'),
          annualBudget: text('Annual Budget ', 'สิทธิ์วงเงินปีนี้ '),
          spendingBudget: text('Budget Spending', 'วงเงินที่ใช้ไป '),
          remainingBudget: text('Budget Remaining', 'วงเงินคงเหลือ '),
          module: text('Module', 'โมดูล'),
          activity: text('Activity News', 'ข่าวสารกิจกรรม'),
          category: {
            myApp: {
              title: text('Module', 'โมดูล'),
              insurance: {
                title: text('Insurance', 'Insurance'),
                description: text('แผนประกันสุขภาพของคุณ', 'แผนประกันสุขภาพของคุณ'),
              },
              eclaim: {
                mainTitle: text('FSA Budget - Dependent', 'วงเงิน FSA ของครอบครัวพนักงาน'),
                title: text('รายการสวัสดิการที่ต้องการเบิก', 'รายการสวัสดิการที่ต้องการเบิก'),
                description: text('', 'เบิกค่ารักษาพยาบาล'),
              },
              marketplace: {
                title: text('Marketplace', 'ร้านค้า'),
                description: text('', 'แลกเหรียญสะสมกับร้านค้าที่ร่วมรายการ'),
              },
              play: {
                title: text('Play', 'Play'),
                description: text('สะสม Coin ผ่านการเข้าร่วมกิจกรรม', 'สะสม Coin ผ่านการเข้าร่วมกิจกรรม'),
              },
              healthCheckup: {
                title: text('Health Checkup', 'Health Checkup'),
                description: text('ตรวจสุขภาพประจำปี', 'ตรวจสุขภาพประจำปี'),
              },
              news: {
                title: text('News', 'News'),
                description: text('ข่าวสารภายในองค์กร', 'ข่าวสารภายในองค์กร'),
              },
              wellBeing: {
                title: text('Well-being', 'Well-being'),
                description: text('ดูแลสุขภาพของคุณ', 'ดูแลสุขภาพของคุณ'),
              }
            },
          },
        },
        play: {
          title: text('Hi', 'สวัสดี'),
          subtitle: text('Activities that support you to live the way you like.', 'กิจกรรมที่สนับสนุนให้คุณได้ใช้ชีวิตในแบบที่ชอบ'),
          events: text('Events', 'กิจกรรม'),
          myevent: text('My Events', 'กิจกรรมที่เข้าร่วมแล้ว'),
          rewardRemain: text('Reward remain', 'รางวัลคงเหลือ'),
          cardList: {
            button: text('Activity details', 'รายละเอียดกิจกรรม')
          },
          upload: {
            gallery: text('Upload photo for activity', 'Upload ภาพเพื่อเข้าร่วมกิจกรรม'),
          },
          joinEvent: text('Join Event', 'เข้าร่วมกิจกรรม'),
          eventFull: text('ขออภัยสำหรับผู้ที่ได้เหรียญครบกำหนดแล้ว', 'ขออภัย จำนวนผู้เข้าร่วมกิจกรรมและได้เหรียญครบกำหนดแล้ว'),
          eventDetail: text('Activity details', 'รายละเอียดกิจกรรม'),
          modalConfirmJoin: {
            title: text('ยืนยันการเข้าร่วมกิจกรรม', 'ยืนยันการเข้าร่วมกิจกรรม'),
            subtitle: text('การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่', 'การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่'),
            submit: text('Submit', 'ยืนยัน'),
            cancel: text('Cancel', 'ยกเลิก'),
            success: text('คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป', 'คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป'),
          }
        },
        footer: {
          link: {
            contactUs: text('Contact us', 'ติดต่อเรา'),
            privacyAndTerms: text('Privacy and terms', 'ข้อกำหนดและเงื่อนไข'),
          },
        },
        login: {
          input: {
            username: {
              label: text('Email', 'อีเมล'),
              placeholder: text('Please enter email address', 'ระบุอีเมล'),
            },
            password: {
              label: text('Password', 'รหัสผ่าน'),
              placeholder: text('Please enter password', 'ระบุรหัสผ่าน'),
            },
          },
          button: {
            submit: text('Login', 'เข้าสู่ระบบ'),
            submitDentsu: text('Login with Fwd account', 'เข้าสู่ระบบ ด้วย Fwd account'),
          },
          content: {
            flexBenefits: text('Flex Benefits', 'Flex Benefits'),
            content1: text(
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
            ),
            content2: text('สามารถทำได้เหมือนกับ Application', 'สามารถทำได้เหมือนกับ Application'),
            content3: text('เข้าสู่ระบบเลย!', 'เข้าสู่ระบบเลย!'),
          },
          formError: {
            username: text('Please input username', 'ระบุชื่อผู้ใช้'),
            password: text('Please input password', 'ระบุรหัสผ่าน'),
          },
        },
        news: {
          header: {
            news: text("News", "ข่าวสาร"),
            event: text("Event", "กิจกรรม"),
          },
          recommend: text('Recommend', 'แนะนำ'),
          more: text('Load More', 'เพิ่มเติม'),
        },
        newsDetail: {
          readButton: text('Accept', 'อ่านแล้ว'),
          modalText: text(
            'คุณได้รับเหรียญจากข่าวนี้เรียบร้อยแล้ว',
            'คุณได้รับเหรียญจากข่าวนี้เรียบร้อยแล้ว',
          ),
          modalTextNoCoin: text('คุณได้อ่านข่าวแล้ว', 'คุณได้อ่านข่าวแล้ว'),
          modalTextAnswer: text('Your request has been submitted', 'เราได้เก็บรวบรวมคำตอบของคุณไว้แล้ว'),
          modalButtonText: text('Agree', 'ตกลง'),
          modalTextFaildQuiz: text('กรุณาเลือกคำตอบทุกข้อ ก่อนส่งคำตอบ', 'กรุณาเลือกคำตอบทุกข้อ ก่อนส่งคำตอบ'),
          modalTextFaildVote: text('ไม่สามารถโหวตได้', 'ไม่สามารถโหวตได้'),
          modalTextFaildVoteSecond: text('กรุณาเลือก Option สำหรับโหวต', 'กรุณาเลือก Option สำหรับโหวต'),
          sentAnswer: text('ส่งคำตอบ', 'ส่งคำตอบ'),
          answerSuccessNews: text('Your request has been submitted', 'เราได้เก็บรวบรวมคำตอบของคุณไว้แล้ว'),
          vote: text('Confirm', 'ยืนยัน'),
          register: text('Register', 'ลงทะเบียน'),
          join: text('Join', 'เข้าร่วม'),
        },
        error: {
          required: text('Required', 'จำเป็นต้องกรอก'),
          numberic: text('Numberic', 'ข้อมูลต้องเป็นตัวเลขเท่านั้น'),
          moreMaxValue: text('More max value', 'จำนวนเหรียญมากกว่าที่สามารถจะใช้ได้'),
          zero: text('Must more 0', 'จำนวนเหรียญต้องมากว่า 0 '),
        },
        setting: {
          header: text('Personal Information', 'ข้อมูลส่วนตัว'),
          contactHeader: text('Contact Information', 'ข้อมูลการติดต่อ'),
          email: text('Email', 'อีเมล'),
          address: text('Address', 'ที่อยู่'),
          phoneNumber: text('Tel', 'เบอร์โทรศัพท์'),
          editButton: text('Edit', 'แก้ไข'),
          familyWelfareHeader: text('Family Information', 'ข้อมูลครอบครัวที่มีสิทธิ์ใช้สวัสดิการ'),
          familyWelfareNote: text(
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
          ),
          citizenId: text('Citizen Id', 'บัตรประชาชน'),
          age: text('Age', 'อายุ'),
          year: text('year', 'ปี'),
          editContactHeader: text('Edit Contact Information', 'แก้ไขข้อมูลการติดต่อ'),
          contactAddress: {
            country: text('country', 'ประเทศ'),
            province: text('province', 'จังหวัด'),
            district: text('district', 'เขต/อำเภอ'),
            subdistrict: text('subdistrict', 'แขวง/ตำบล'),
            postcode: text('postcode', 'ไปรษณีย์'),
          },
          confirmbutton: text('Confirm', 'ยืนยัน'),
          submitButton: text('Save', 'บันทึก'),
          changeLang: text('Change Language', 'เปลี่ยนภาษา'),
          version: text('Version', 'เวอร์ชั่น'),
        },
        shop: {
          redeem: text('Redeem', 'ใช้เหรียญเพื่อแลก'),
          expired: text('Expired', 'หมดเขตวันที่'),
          seeMore: text('See More', 'เพิ่มเติม'),
        },
        termAndCondition: {
          header: text('Terms and Conditions', 'ข้อกำหนดและเงื่อนไข'),
          subHeader: text(subHeaderEn, subHeaderTh),
          webAccessHeader: text('1. การเข้าใช้งานเว็บไซต์ของเรา', '1. การเข้าใช้งานเว็บไซต์ของเรา'),
          webAccessText: text(webAccessTextEn, webAccessTextTh),
          changeTermHeader: text('2. Change of Specification', '2. การเปลี่ยนแปลงข้อกำหนด'),
          changeTermText: text(changeTermTextEn, changeTermTextTh),
          lawHeader: text('3. กฎหมายที่ใช้บังคับ', '3. กฎหมายที่ใช้บังคับ'),
          lawText: text(lawTextEn, lawTextTh),
        },
        about: {
          header: text('About Belive', 'เกี่ยวกับ Belive'),
          content_1B: text(`BE`, `BE`),
          content_1: text(
            ` The Power to choose and take action by self`,
            ` The Power to choose and take action by self`,
          ),
          content_2B: text(`LIVE `, `LIVE `),
          content_2: text(` Our promise to people`, ` Our promise to people`),
          content_3B: text(`BENEFIT OF LIVING`, `BENEFIT OF LIVING`),
          content_3: text(
            ` Believe in the pursuit of lifestyle for life fulfillment`,
            ` Believe in the pursuit of lifestyle for life fulfillment`,
          ),
          content_4: text(aboutTextEn, aboutTextTh),
        },
        contact: {
          header: text('Contact Us', 'ติดต่อเรา'),
          phoneLabel: text('Tel', 'เบอร์โทรศัพท์'),
          emailLabel: text('Email', 'อีเมล'),
          addressLabel: text('Address', 'ที่อยู่'),
        },
        comment: {
          header: text('COMMENT', 'COMMENT'),
          placeholder: text('Comment...', 'ความคิดเห็น...'),
          placeholderFull: text('Your Comment ...', 'แสดงความคิดเห็น...'),
          submitButton: text('Send', 'แสดงความคิดเห็น'),
          commentCount: text('Comments', 'ความคิดเห็น'),
          commentEmpty: text('No Comment', 'ไม่มีการแสดงความคิดเห็น'),
          loading: text('Loading Comment...', 'กำลังโหลดคอมเมนต์...'),
        },
        appShortcut: {
          pinToHome: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
          pinToHomeShort: text('หน้าแรก', 'หน้าแรก'),
          unPinToHome: text('เพิ่มไปยังหน้าแรกแล้ว', 'เพิ่มไปยังหน้าแรกแล้ว'),
          unPinToHomeShort: text('เพิ่มแล้ว', 'เพิ่มแล้ว'),
          pinFaildShort: text('ไม่สามารถเพิ่มได้', 'ไม่สามารถเพิ่มได้'),
          pinShortCut: text('App Shortcut', 'App Shortcut'),
          confirm: text('ยืนยัน', 'ยืนยัน'),
          ok: text('Confirm', 'ตกลง'),
          pinModal: {
            title: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
            messageStart: text('ต้องการเพิ่ม App ', 'ต้องการเพิ่ม App '),
            messageEnd: text(' ไปยังหน้าแรก', ' ไปยังหน้าแรก'),
          },
          unPinModal: {
            title: text('นำออกจากหน้าแรก', 'นำออกจากหน้าแรก'),
            messageStart: text('ต้องการนำ App ', 'ต้องการนำ App '),
            messageEnd: text(' ออกจากหน้าแรก', ' ออกจากหน้าแรก'),
          },
        },
        shopRewardClaim: {
          header: text('ยืนยันการหักเหรียญ / ชำระส่วนต่าง', 'ยืนยันการหักเหรียญ / ชำระส่วนต่าง'),
          successHeader1: text('คุณได้ทำรายการ', 'คุณได้ทำรายการ'),
          successHeader2: text('หักเหรียญสำเร็จแล้ว', 'หักเหรียญสำเร็จแล้ว'),
          currentUserPoint: text('เหรียญที่มีอยู่', 'เหรียญที่มีอยู่'),
          pointOfProduct: text('หักเหรียญที่มีอยู่', 'หักเหรียญที่มีอยู่'),
          pointLeft: text('เหรียญคงเหลือ', 'เหรียญคงเหลือ'),
          claimButtonText: text('Redeem with Belive Coin', 'ใช้เหรียญเพื่อแลก'),
          confirm: text('Confirm', 'ตกลง'),
        },
        pointHistory: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Transaction History', 'ประวัติการใช้เหรียญ'),
          coin: text('เหรียญ', 'เหรียญ'),
          dealDate: text('แลกเมื่อ', 'แลกเมื่อ'),
        },
        notification: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Notification', 'การแจ้งเตือน'),
        },
        enrollment: {
          fsaBudget: text('FSA Budget - ', 'วงเงิน FSA ของ'),
          fsaTitle: text('FSA Budget - Employee', 'วงเงิน FSA ของพนักงาน'),
          insurance: text('Insurance Plan', 'แผนประกัน'),
          myInsurance: text('Self Insurance', 'ประกันของตัวเอง'),
          titleInsurance: text('ท่านสามารถปรับเปลี่ยนแผนประกันที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่', 'ท่านสามารถปรับเปลี่ยนแผนประกันที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          insuranceList: text('List of available flexible benefit', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('Your insurance plan', 'ประกันของคุณ'),
            insuranceSelected: text('Selected insurance plan', 'แผนประกันที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeInsurance: text('Change insurance plan', 'เปลี่ยนแผนใหม่'),
            addInsurance: text('Choose your own insurance plan', 'เลือกแผนประกันตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบข้อมูลและรายละเอียดกรมธรรม์และค่าเบี้ยประกันของแผนประกันสุขภาพและประกันอุบัติเหตุ สำหรับตนเอง/ครอบครัวประจำปี ได้โดยคลิกที่ไฟล์ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบข้อมูลและรายละเอียดกรมธรรม์และค่าเบี้ยประกันของแผนประกันสุขภาพและประกันอุบัติเหตุสำหรับตนเอง/ครอบครัวประจำปี ได้โดยคลิกที่ไฟล์ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select your insurance plan', 'เลือกแผนประกันของตนเอง'),
            select: text('Select insurance plan', 'เลือกแผนประกัน'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              title: text('Summary of coverage options', 'สรุปการเลือกความคุ้มครอง'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
            }
          },
          modalAcceptPrivacy: {
            header: text('Consent letter', 'หนังสือยินยอม'),
            title: text('Terms and Conditions', 'ข้อตกลงและเงื่อนไข'),
            accept: text('I hereby confirm that I choose this insurance plan with my consideration.', 'คุณยืนยันเลือกแบบแผนประกันนี้ด้วยตนเอง'),
            confirm: text('Confirm', 'ตกลง'),
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text('พนักงานทีมีสิทธิ์เลือกแผนประกัน คือ พนักงานที่ผ่านทดลองก่อนวันที่', 'พนักงานทีมีสิทธิ์เลือกแผนประกัน คือ พนักงานที่ผ่านทดลองก่อนวันที่'),
            list2: text('กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน', 'กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน'),
            list3: text('หากท่านเลือกปรับแผนความคุ้มครองสูงขึ้น ค่าใช้จ่ายส่วนต่างจะถูกเรียกเก็บจากท่านผ่าน payroll', 'หากท่านเลือกปรับแผนความคุ้มครองสูงขึ้น ค่าใช้จ่ายส่วนต่างจะถูกเรียกเก็บจากท่านผ่าน payroll'),
          }
        },
        healthCheckup: {
          healthCheckup: text('Hospital', 'โรงพยาบาล'),
          myHealthCheckup: text('Self health checkup hospital', 'โรงพยาบาลของตนเอง'),
          titleHealthCheckup: text('ท่านสามารถเลือกโรงพยาบาลตรวจสุขภาพที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่', 'ท่านสามารถเลือกโรงพยาบาลตรวจสุขภาพที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          insuranceList: text('List of available flexible benefit', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('My Hospital', 'โรงพยาบาลของคุณ'),
            healthCheckupSelected: text('Selected Hospital', 'โรงพยาบาลที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeHealthCheckup: text('Change health checkup hospital', 'เลือกโรงพยาบาล'),
            addHealthCheckup: text('Select health checkup hospital', 'เลือกโรงพยาบาลตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบข้อมูลและรายละเอียดกรมธรรม์และค่าเบี้ยประกันของแผนประกันสุขภาพและประกันอุบัติเหตุ สำหรับตนเอง/ครอบครัวประจำปี ได้โดยคลิกที่ไฟล์ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบข้อมูลและรายละเอียดกรมธรรม์และค่าเบี้ยประกันของแผนประกันสุขภาพและประกันอุบัติเหตุสำหรับตนเอง/ครอบครัวประจำปี ได้โดยคลิกที่ไฟล์ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select health checkup hospital', 'เลือกโรงพยาบาลตรวจสุขภาพ'),
            select: text('Select Hospital', 'เลือกโรงพยาบาล'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              title: text('Summary of hospital selection', 'สรุปการเลือกโรงพยาบาล'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
            }
          },
          modalAcceptPrivacy: {
            header: text('Consent letter', 'หนังสือยินยอม'),
            title: text('Terms and Conditions', 'ข้อตกลงและเงื่อนไข'),
            accept: text('คุณยืนยันเลือกโรงพยาบาลนี้สำหรับตรวจสุขภาพ', 'คุณยืนยันเลือกโรงพยาบาลนี้สำหรับตรวจสุขภาพ'),
            confirm: text('Confirm', 'ตกลง'),
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text('พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2566 คือ พนักงานที่เข้างานก่อนวันที่ 1 มีนาคม 2566 และผ่านทดลองงานแล้วเท่านั้น', 'พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2566 คือ พนักงานที่เข้างานก่อนวันที่ 1 มีนาคม 2566 และผ่านทดลองงานแล้วเท่านั้น'),
            list2: text('กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกโรงพยาบาลสมิติเวชให้โดยอัตโนมัติ', 'กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกโรงพยาบาลสมิติเวชให้โดยอัตโนมัติ'),
            list3: text('หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายน้อยกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญส่วนต่างเพิ่ม', 'หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายน้อยกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญส่วนต่างเพิ่ม'),
            list4: text('ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง', 'ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง')
          }
        },
        eClaim: {
          confirmCancel: text('You want to cancel claim', 'คุณต้องการยกเลิกการเบิกสวัสดิการ ?'),
          confirmButton: text('Confirm Cancel', 'ต้องการยกเลิก'),
          cancelClaimButton: text('Not Cancel', 'ไม่ยกเลิก'),
          items: text('Claim Items', 'รายการเบิก'),
          homeButton: text('Return to homepage', 'กลับไปหน้าแรก'),
          cancelButton: text('Cancel', 'ยกเลิกการเบิก'),
          submitButton: text('Submit', 'ยื่นคำขอ'),
          checkBalanceButton: text('Check Balance', 'ตรวจสอบสิทธิ'),
          backButton: text('Back', 'ย้อนกลับ'),
          newRequestButton: text('Create new request', 'เบิกใหม่อีกครั้ง'),
          nextButton: text('Next', 'ถัดไป'),
          claimPriceError: text('Cannot greather than claim price', 'ต้องไม่มากกว่า Claim Price'),
          infoForCheck: text('Instructions and details of receipt', 'คำแนะนำในการออกใบเสร็จรับเงิน'),
          coinError: text(
            'Cannot greather than User coin',
            'จำนวนที่ขอเบิกต้องไม่มากกว่าเหรียญที่มี',
          ),
          name: text('name', 'ชื่อ'),
          status: text('status', 'สถานะ'),
          info: {
            submitted: text('Submitted', 'ส่งเรื่องเบิกเรียบร้อยแล้ว'),
            checkDetails: text('Check the details ', 'ตรวจสอบรายละเอียด '),
            inputDetails: text('Fill in the details of claim/reimbursement', 'กรอกรายละเอียดการเบิกสวัสดิการ'),
          },
          field: {
            completeClaim: text('Completed claim items', 'รายการที่เบิกไปแล้ว'),
            damageInfo: text('Details of the damage', 'รายละเอียดของความเสียหาย'),
            claimFor: text('Claim for', 'เบิกให้'),
            claimFor2: text('Reimbursement for employee/family member who passed away.', 'เบิกแทน พนักงาน/ครอบครัวพนักงานที่สูญเสีย'),
            actualAmount: text('Actual claim amount', 'จำนวนเงินที่เบิกได้จริง'),
            receiptDate: text('Receipt date', 'วันที่ตามใบเสร็จรับเงิน'),
            docNum: text('Document number', 'เลขทำรายการ'),
            dateClaim: text('Request date', 'วันที่ขอเบิก'),
            status: text('Status', 'สถานะ'),
            fullName: text('Name - surname of beneficiary', 'ชื่อ - นามสกุล ผู้ขอเบิก'),
            claimDate: text('Document date', 'วันที่เอกสาร'),
            claimDate2: text('Receipt date / Document date', 'วันที่ตามใบเสร็จรับเงิน / วันที่เอกสาร'),
            amountReceipt: text('Amount stated on the receipt', 'จำนวนเงินตามหน้าใบเสร็จรับเงิน'),
            amountEntitlement: text('Amount per benefits entitlement', 'จำนวนเงินตามสิทธิสวัสดิการ'),
            typeOfDamage: text('Type of Damage', 'ประเภทความเสียหาย'),
            summarizeDamage: text('Summarize the incident and provide details of the damage to the house or belongings', 'สรุปเหตุการณ์และแจ้งรายละเอียดของความเสียหายของบ้านหรือรายการสิ่งของ'),
            addFile: text('Add file', 'เพิ่มไฟล์'),
          },
          typeOfDamage: {
            floods: text('Floods', 'น้ำท่วม'),
            earthquakes: text('Earthquakes', 'แผ่นดินไหว'),
            fire: text('Fire', 'ไฟไหม้'),
            landslides: text('Landslides', 'ดินถล่ม'),
            windstorms: text('Windstorms', 'พายุถล่ม'),
          },
          claimTopics: {
            first: text('1. Emergency Allowance 3,000 Baht', '1. เงินช่วยเหลือจำเป็นเร่งด่วน 3,000 ฿'),
            second: text('2. Temporary accommodation fee (actual expenses, maximun 20,000 Baht per month and maximum 1 month per incident)', '2. ค่าที่พักอาศัยชั่วคราว (เบิกตามจริงไม่เกิน 20,000 ฿ต่อเดือนและไม่เกิน 1 เดือนต่อเหตุการณ์)'),
            third: text('3. Travel expenses as needed (actual expenses, maximum 300 Baht per day and maximum 10 days per incident)', '3. ค่าเดินทางตามความจำเป็น (เบิกตามจริงไม่เกิน 300 ฿ต่อวัน และไม่เกิน 10 วันต่อเหตุการณ์)'),
            fourth: text('4. Damage aid to houses and/or home appliances according to policy', '4. เงินช่วยเหลือค่าความเสียหายของบ้าน และ/หรือ สิ่งของเครื่องใช้ภายในบ้าน ตามนโยบาย'),
          },
          context: {
            first: text('We will contact you by e-mail if there is any issues. You can track your status via Claims History.', 'รอเจ้าหน้าที่ตรวจสอบ หากพบปัญหา เจ้าหน้าที่จะติดต่อท่านผ่านทางอีเมล ท่านสามารถตรวจสอบสถานะการเบิกที่ประวัติการเบิก'),
            second: text('If you submit by the 5th, the claim amount will be credited to your payroll account within the same month.', 'หากส่งเบิกภายในวันที่ 5 ของเดือน เงินจะเข้าบัญชี payroll ในเดือนเดียวกัน'),
            third: text('If you submit after the 5th, the claim amount will be credited to your payroll account by next month.', 'หากส่งเบิกหลังจากวันที่ 5 ของเดือน เงินจะเข้าบัญชี payroll ในเดือนถัดไป'),
            fourth: text(`Reminder! Please submit all original documents to 23rd floors, O-NES Tower, at Utility Room within 7 days after the status are approved. (Except e-Receipt)`, `อย่าลืม! ส่งเอกสารต้นฉบับทั้งหมดที่ ชั้น 23 อาคารโอเนสทาวเวอร์ ห้อง Utility Room ภายใน 7 วันหลังจากเอกสารได้รับอนุมัติ (ยกเว้นใบเสร็จรับเงินอิเล็กทรอนิกส์)`),
            fifth: text('We will contact you by e-mail after checking all documents request.', 'รอเจ้าหน้าที่ตรวจสอบ และจะติดต่อท่านผ่านทางอีเมล'),
            sixth: text('Reminder! Please submit all original documents to 23rd floors, O-NES Tower, at Utility Room  after submission. (Except e-Receipt)', 'อย่าลืม! ส่งเอกสารต้นฉบับทั้งหมดที่ ชั้น 23 อาคารโอเนสทาวเวอร์ ห้อง Utility Room หลังจากส่งคำขอผ่านระบบเรียบร้อยแล้ว (ยกเว้นใบเสร็จรับเงินอิเล็กทรอนิกส์)'),
            seventh: text('Reminder! Please submit all original documents to 23rd floors, O-NES Tower, at Utility Room within 7 days after the status are approved. (Except e-Receipt)', 'อย่าลืม! ส่งเอกสารต้นฉบับใบเสร็จรับเงินที่ ชั้น 23 อาคารโอเนสทาวเวอร์ ห้อง Utility Room ภายใน 7 วันหลังจากเอกสารได้รับการอนุมัติ (ยกเว้นใบเสร็จรับเงินอิเล็กทรอนิกส์)')
          },
          provident: {
            first: text('Please clarify as per below conditions.', 'กรุณาระบุข้อมูลเพื่อใช้ในการพิจารณาเงื่อนไขสวัสดิการเงินกู้ฉุกเฉิน'),
            second: text('Employees are in the process of paying installment for an emergency loan with FWD.', 'พนักงานยังอยู่ในระหว่างการผ่อนค่างวดเงินกู้ฉุกเฉินกับบริษัท'),
            third: text('Employees are listed as guarantors with FWD.', 'พนักงานยังอยู่ในระหว่างการเป็นผู้ค้ำประกันเงินกู้ฉุกเฉินให้กับพนักงานบริษัท'),
            fourth: text("Employees are not members of the company's provident fund.", 'พนักงานไม่ได้เป็นสมาชิกกองทุนสำรองเลี้ยงชีพของบริษัท'),
            fifth: text('None of the above conditons', 'ไม่เข้าเงื่อนไขทั้ง 3 ข้อ'),
            sixth: text('Your request does not exceed loan limit but,', 'คุณขอกู้ไม่เกินสิทธิแต่'),
            seventh: text('Your request exceeds loan limit and', 'คุณขอกู้เกินสิทธิและ'),
            ninth: text("You do not meet company loan's criterias", 'คุณมีคุณสมบัติไม่ตรงตามสิทธิการขอเงินกู้ '),
            ten: text("You meet the initial company loan's criterias. Click OK to attach the relevant loan documents", 'คุณมีคุณสมบัติตรงตามสิทธิการขอเงินกู้ในเบื้องต้น คลิกตกลง เพื่อแนบเอกสารเสนอขอเงินกู้'),
          }
        },
      },
    },
  }
}
